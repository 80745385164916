import { styled } from '@mui/material';
import { cloneDeep, sumBy } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicButton, Chip, Spinner } from '../../components/atoms';
import { Typography } from '../../components/atoms/Typography/Typography';
import {
    ConfirmationDialog,
    PlanInfoAndReRunSection,
    UniversalCard
} from '../../components/molecules';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import {
    PlanSummaryProgressBar,
    PlanSummaryProgressBarValue
} from '../../components/molecules/PlanSummaryProgressBar/PlanSummaryProgressBar';
import { ProbabilityOfSuccess } from '../../components/molecules/ProbabilityOfSuccess/ProbabilityOfSuccess';
import PortfolioCompositionContainer from '../../components/organisms/PortfolioComposition';
import { runpipe } from '../../services/clients/runpipe';
import { syncAdviceGoals, updateBanner } from '../../features/client-goals';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { ProjectedWealthPathChart } from '../../components/molecules/ProjectedWealthPathChart';
import { Tabs, TabVariant } from '../../components/molecules/Tabs/Tabs';
import { AllocationsOverTimeTab } from './AllocationsOverTimeTab';
import { GoalReviewPlanPDF } from '../../utils/pdfExport';
import { goalcalculator } from '../../services/clients/goalcalculator';
import { handleGoalCalculatorResponse } from '../../features/client-goals';
import dayjs from 'dayjs';
import { RetirementGoals } from '../../containers/retirement-goals-drawer';
import { WealthGoals } from '../../containers/wealth-goals-drawer';
import { updateCurrentStep } from '../../features/client';
import { UnrealisticGoal } from './UnrealisticGoal';

export interface RecomendationProps {
    isRecomendation?: boolean;
}

const StyledPositionOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(28),
    top: theme.spacing(19.3)
}));

export const StyledDisclaimerContent = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: theme.spacing(1.5),
    margin: `${theme.spacing(3)} 0`,
    lineHeight: theme.spacing(2),
    color: theme.palette.grey[300]
}));

const ProbSuccessDiv = styled('div')<RecomendationProps>(({ isRecomendation = false }) => ({
    display: 'flex',
    justifyContent: isRecomendation ? 'left' : 'center',
    marginTop: '20px'
}));

const HeaderDiv = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
}));

const GoalSummary = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        goalId,
        goalJson,
        goeConfig,
        goalsData,
        goalcalculatorJson,
        goalName,
        goalType,
        isGoalRealistic,
        isRetirement,
        goalDataRecomendation,
        updateStep,
        type
    } = props;
    const global = useAppSelector((state) => state.global);
    const { portfolio: allMarketPortfolios } = useAppSelector((state) => state.global);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const goalResp = cloneDeep(goalJson[0]);
    const HEADERS = {
        clientemail: global?.orgInfo?.orgEmail,
        version: 4
    };

    // will use this later if required
    // const findYears = useCallback(
    //     (probData: any) => {
    //         if (probData != 'NA') {
    //             if (!isRetirement) {
    //                 const splitedData = probData.split(';');
    //                 let years = 0;
    //                 let splitedDataPer = splitedData[0].split(':');
    //                 const splitedDataPerT = splitedDataPer[0].trim().split(' ');
    //                 years = parseInt(splitedDataPerT[3].split('+')[1]);
    //                 return years;
    //             } else {
    //                 const splitedData = probData.split(';');
    //                 let years = 0;
    //                 let splitedDataPer = splitedData[0].split(':');
    //                 if (splitedData.length > 1) {
    //                     splitedDataPer = splitedData[1].split(':');
    //                 }
    //                 const splitedDataPerT = splitedDataPer[0].trim().split(' ');
    //                 splitedDataPerT.forEach((element: any) => {
    //                     if (Number(element)) {
    //                         years = Number(element);
    //                     }
    //                 });
    //                 return years;
    //             }
    //         }
    //         return 0;
    //     },
    //     [goalResp]
    // );

    const calculateInitialAmount = (accounts = []) => {
        const { incomeSource = [], investmentSource } =
            adviceGoals?.formFields?.find?.((item) => item.goalId === goalId)?.data?.sources || {};

        const sumIncomeSource = sumBy(incomeSource, ({ isSelected, accountBalance }: any) => {
            if (!isSelected) {
                return 0;
            }

            return accountBalance;
        });

        const sumInvestmentSource = sumBy(
            investmentSource,
            ({ isRCSelected, frequency, recurringContributions }: any) => {
                if (!isRCSelected) {
                    return 0;
                }

                if (frequency === 'Annually') {
                    return recurringContributions;
                }

                return recurringContributions * 12;
            }
        );

        const annualContribution = sumIncomeSource + sumInvestmentSource;

        const initialInvestment = sumBy(accounts, 'amount');

        return { initialInvestment, annualContribution };
    };

    function calculateYearsBetweenDates(startDate: any, dateStr: string) {
        const [day, month, year] = dateStr.split('-').map(Number);
        const endDate = new Date(year, month - 1, day);
        const start = new Date(startDate);
        const end = new Date(endDate);
        let years = end.getFullYear() - start.getFullYear();
        let months = end.getMonth() - start.getMonth();
        if (months < 0) {
            years--;
            months += 12;
        }
        const fractionalYears = years + months / 12;
        return fractionalYears.toFixed(1);
    }

    const applyRecommendation = async (selectedRecomendationData: any) => {
        const goalJson = cloneDeep(goalsData);
        if (selectedRecomendationData.key === 'oneTimeTopUp') {
            goalJson.accounts.push({
                fundingType: 'investmentAccounts',
                recommendationType: 'oneTimeTopUp',
                amount: selectedRecomendationData.optionValue,
                recurringContribution: 0,
                escalationPercentage: 0,
                escalationYears: 1,
                frequency: 'yearly',
                startDate: dayjs().format('DD-MM-YYYY'),
                endDate: goalsData.targetDate
            });
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, accounts: goalJson.accounts }
                    : item;
            });
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, ...runpipeResponse }
                    : item;
            });
            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, oneTimeTopUp: selectedRecomendationData.optionValue }
                    : item;
            });
            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );
            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map(
                (item: any) => {
                    return item?.goalId && item.goalId === goalId
                        ? { ...item, ...GcalculatorResponse }
                        : item;
                }
            );
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        } else if (selectedRecomendationData.key === 'topUpAccumulation') {
            goalJson.accounts.push({
                fundingType: 'investmentAccounts',
                amount: 0,
                recommendationType: 'topUpAccumulation',
                recurringContribution: selectedRecomendationData.optionValue,
                escalationPercentage: 0,
                escalationYears: 1,
                frequency: 'yearly',
                startDate: dayjs().format('DD-MM-YYYY'),
                endDate: goalsData.targetDate,
                includeRecurringContributionsForGoal: goalId
            });
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, accounts: goalJson.accounts }
                    : item;
            });
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, ...runpipeResponse }
                    : item;
            });

            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, topUpAccumulation: selectedRecomendationData.optionValue }
                    : item;
            });

            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );

            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map(
                (item: any) => {
                    return item?.goalId && item.goalId === goalId
                        ? { ...item, ...GcalculatorResponse }
                        : item;
                }
            );
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        } else if (selectedRecomendationData.key === 'recommendedTenure') {
            const dateStr = goalsData.targetDate;
            const [day1, month1, year1] = dateStr.split('-').map(Number);
            const date = new Date(year1, month1 - 1, day1);
            const newDate = date.setFullYear(
                date.getFullYear() + selectedRecomendationData.optionValue
            );
            var updatedDate = new Date(newDate);
            // Extract day, month, and year
            var day = updatedDate.getDate();
            var month = updatedDate.getMonth() + 1;
            var year = updatedDate.getFullYear();
            const formattedDay: string = day < 10 ? '0' + day : day.toString();
            const formattedMonth: string = month < 10 ? '0' + month : month.toString();
            // Format the date as dd-mm-yyyy
            const formattedDate: string = formattedDay + '-' + formattedMonth + '-' + year;
            if (!isRetirement) goalJson['targetDate'] = formattedDate;
            if (isRetirement) {
                const dateStr = goalsData.planStartRetirement;
                const [day1, month1, year1] = dateStr.split('-').map(Number);
                const date = new Date(year1, month1 - 1, day1);
                const newDate = date.setFullYear(
                    date.getFullYear() + selectedRecomendationData.optionValue
                );
                updatedDate = new Date(newDate);
                // Extract day, month, and year
                day = updatedDate.getDate();
                month = updatedDate.getMonth() + 1;
                year = updatedDate.getFullYear();
                const formattedDay: string = day < 10 ? '0' + day : day.toString();
                const formattedMonth: string = month < 10 ? '0' + month : month.toString();
                // Format the date as dd-mm-yyyy
                const formattedDate: string = formattedDay + '-' + formattedMonth + '-' + year;
                goalJson['planStartRetirement'] = formattedDate;
            }
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, targetDate: formattedDate }
                    : item;
            });
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, ...runpipeResponse }
                    : item;
            });

            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, recomendedTenure: selectedRecomendationData.optionValue }
                    : item;
            });

            let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
            originalGoalsData?.formFields.forEach((item) => {
                let itemData = item['data'];
                if (item.goalId === goalsData?.goalId && itemData.goalType === 'retirement') {
                    itemData['planStartRetirement'] = goalJson['planStartRetirement'];
                }
            });
            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    formFields: originalGoalsData['formFields'],
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );
            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map(
                (item: any) => {
                    return item?.goalId && item.goalId === goalId
                        ? { ...item, ...GcalculatorResponse }
                        : item;
                }
            );
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        } else if (isRetirement && selectedRecomendationData.key === 'topUpDecumulation') {
            const newIncome =
                goalJson['targetedRetirementIncome'] - selectedRecomendationData.optionValue;
            goalJson['targetedRetirementIncome'] = newIncome;
            const runpipeResponse = await runpipe(goalJson, HEADERS);
            const runpipeRequestData = adviceGoals.request.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, targetedRetirementIncome: newIncome }
                    : item;
            });
            const runpipeResponseData = adviceGoals.response.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, ...runpipeResponse }
                    : item;
            });

            const recomendationResp = adviceGoals.recommendation.map((item: any) => {
                return item?.goalId && item.goalId === goalId
                    ? { ...item, toUpDeccumulation: selectedRecomendationData.optionValue }
                    : item;
            });

            dispatch(
                syncAdviceGoals({
                    ...adviceGoals,
                    request: runpipeRequestData,
                    response: runpipeResponseData,
                    recommendation: recomendationResp
                })
            );

            goalJson['goalAmount'] = 0;
            const GcalculatorResponse = await goalcalculator(goalJson, HEADERS);
            const GcalculatorResponseData = adviceGoals.global?.responseGoalCalculator?.map(
                (item: any) => {
                    return item?.goalId && item.goalId === goalId
                        ? { ...item, ...GcalculatorResponse }
                        : item;
                }
            );
            dispatch(handleGoalCalculatorResponse(GcalculatorResponseData));
        }
    };

    const goalAmtRetirement = (arr: any) => {
        let min;
        for (let i of arr) {
            if (i < 0) {
                min = i;
                break;
            }
        }

        return -1 * min;
    };

    const startDate = new Date();
    const dateStr = goalsData.targetDate;
    const [day, month, year] = dateStr.split('-').map(Number);
    const endDate = new Date(year, month - 1, day);
    // const yearsDifference = calculateYearsBetweenDates(startDate, goalsData.targetDate);
    const infusionArray = goalcalculatorJson?.[0]?.['response']?.['body']?.['infusions'];
    const [tabIndex, setTabIndex] = useState('0');
    const calculatedAmountval = calculateInitialAmount(goalsData.accounts);
    const analysisReport = goalResp['response']['body']['analysisReport'];
    const pathReport = goalResp['response']['body']['pathReport'];
    const goalPriorityValue = goeConfig['pipe_config']['goal_priority_prob_list'];
    const goalUnrealisticValue = goeConfig['pipe_config']['realistic_goal_prob'];
    const currentGoalProbability = ~~Number(analysisReport['currentGoalProbability'] * 100);
    const goalPriorityProbability =
        goalPriorityValue[goalsData['goalPriority'] as 'Dream' | 'Want' | 'Wish' | 'Need'] * 100;
    const isRecomendation = currentGoalProbability >= goalPriorityProbability;
    const probableGoalAmount = isRetirement
        ? goalAmtRetirement(infusionArray)
        : goalcalculatorJson[0]?.response?.body?.goalAmt;
    const goalAmount = isRetirement
        ? goalsData['targetedRetirementIncome']
        : goalsData['goalAmount'];
    // const originalAmount = goalDataRecomendation[0].orginalAmount;
    const isRecomendationClicked =
        goalDataRecomendation[0].oneTimeTopUp != 0 ||
        goalDataRecomendation[0].topUpAccumulation != 0 ||
        goalDataRecomendation[0].toUpDeccumulation != 0 ||
        goalDataRecomendation[0].recomendedTenure != 0;

    // const isRecomendation = isRetirement ? (analysisReport['oneTimeTopUp'] == 0 && analysisReport['yearlyTopUpAccumulation']==0 && recomendedTenure==0 && analysisReport['yearlyTopUpDecumulation']<=0) : (analysisReport['oneTimeTopUp'] == 0 &&
    // analysisReport['yearlyTopUpAccumulation']==0 && recomendedTenure==0); // To Do on decumulation negative value // for future use
    const wealthPath = adviceGoals.response.find((item: any) => {
        return item?.goalId && item.goalId === goalId;
    }).response?.body?.pathReport?.wealthPath;
    const getYears = () => {
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const years = [startYear];
        for (let i = startYear; i < endYear; i++) {
            years.push(i + 1);
        }
        return years;
    };

    function extractYearRetirement(data: string, value: number): number | null {
        // Split the data string into individual parts
        const parts = data.split(';').map((part) => part.trim());

        const adjustments: { [key: number]: number } = {};

        console.log('data string', data);
        // Process each part to extract years and percentages
        parts.forEach((part) => {
            const years = parseInt(part.split('by')[1].split('years')[0].trim());
            const percentage = parseInt(part.split(':')[1].replace('%', '').trim());
            adjustments[years] = percentage;
        });

        // Iterate through the adjustments to find the first percentage greater than the value
        for (const years of Object.keys(adjustments).map(Number)) {
            if (adjustments[years] >= value) {
                return years; // Return the first years found with percentage greater than value
            }
        }

        return 0; // Return null if no suitable adjustment is found
    }

    // will use this later
    function extractYearForProbability(probData: string): number | null {
        if (probData === 'NA') {
            return 0; // or any other indication of invalid input
        }

        let resultYear: number | null = 0;
        if (isRetirement) {
            resultYear = extractYearRetirement(probData, goalPriorityProbability);
        } else {
            // Loop through the split data
            const splitedData = probData.split(';');
            for (const dataEntry of splitedData) {
                const match = dataEntry.match(/T\+(\d+) years\s*:\s*(\d+)%/);
                if (match) {
                    const year = parseInt(match[1]);
                    const percentage = parseInt(match[2]);
                    // Check if the percentage is greater than or equal to the target value
                    if (percentage >= goalPriorityProbability) {
                        return year; // Return the first year that meets the condition
                    }
                }
            }
        }
        return resultYear; // Return the year or 0 if not found
    }

    let bankruptcyYear: number | undefined = undefined;

    const chartData = getYears().map((year, index) => {
        if (wealthPath?.default?.[index] === 0 && !bankruptcyYear) {
            bankruptcyYear = year;
        }
        return {
            year,
            target: Array.isArray(wealthPath) ? wealthPath[index] : wealthPath?.default?.[index],
            low: Array.isArray(wealthPath) ? 0 : wealthPath?.pessimistic?.[index],
            high: Array.isArray(wealthPath) ? 0 : wealthPath?.optimistic?.[index],
            bankruptcyHigh: bankruptcyYear ? Math.max(...wealthPath?.optimistic) ?? 0 : 0,
            bankruptcyLow: 0
        };
    });
    const pdfContainerRef = useRef<HTMLDivElement>(null);

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const exportPdf = async () => {
        setIsGeneratingPdf(true);
        if (pdfContainerRef?.current) {
            try {
                await new GoalReviewPlanPDF().export(pdfContainerRef.current);
            } catch (error) {
                console.error('Error in generating PDF', error);
            }
            setIsGeneratingPdf(false);
        }
    };

    const [showGoalDrawer, setShowGoalDrawer] = useState(false);

    const yearsInRetirement = calculateYearsBetweenDates(
        isRetirement ? goalsData?.planStartRetirement : '',
        isRetirement ? goalsData?.targetDate : ''
    );
    const handleCancelDelete = () => {
        setDeleteModalState(false);
    };

    const handleConfirmDelete = () => {
        const updatedGoals = adviceGoals.formFields.filter((goal) => goal.goalId !== goalId);
        dispatch(
            syncAdviceGoals({
                ...adviceGoals,
                formFields: updatedGoals
            })
        );
        dispatch(updateCurrentStep(1));
        dispatch(
            updateBanner({
                type: 'attention',
                isGoalSummary: true,
                visible: true
            })
        );
        updateStep(1);
    };

    // TODO:: Keeping this comment until we find a space to put in 👇

    // useEffect(() => {
    //     if (adviceGoals.global.probabilityOfSuccess !== currentGoalProbability) {
    //         dispatch(
    //             syncAdviceGoals({
    //                 ...adviceGoals,
    //                 global: { ...adviceGoals?.global, probabilityOfSuccess: currentGoalProbability }
    //             })
    //         );
    //     }
    // }, [currentGoalProbability, adviceGoals, dispatch]);

    const barPrecentage =
        probableGoalAmount < goalAmount
            ? (probableGoalAmount / goalAmount) * 100
            : (goalAmount / probableGoalAmount) * 100;

    const planSummaryProgressBarValues = useMemo<PlanSummaryProgressBarValue[]>(() => {
        const targetGoalAmountLabel = isRetirement ? t('TARGET_ANNUAL_INCOME') : t('TARGET_GOAL');

        if (goalAmount == probableGoalAmount) {
            return [
                {
                    color: '#416AF4',
                    label: targetGoalAmountLabel,
                    percentage: 100
                }
            ];
        }

        if (probableGoalAmount < goalAmount) {
            return [
                {
                    color: '#416AF4',
                    label: targetGoalAmountLabel,
                    percentage: barPrecentage
                },
                {
                    color: '#E4E5E8',
                    label: 'Shortfall',
                    descText: 'Shortfall below Target',
                    percentage: 100 - barPrecentage
                }
            ];
        }

        return [
            {
                color: '#416AF4',
                label: targetGoalAmountLabel,
                percentage: barPrecentage
            },
            {
                percentage: 100 - barPrecentage,
                color: '#00BFB3',
                label: 'Excess',
                descText: 'Excess over Target'
            }
        ];
    }, [goalAmount, probableGoalAmount, barPrecentage, isRetirement]);

    const handleTabChange = (event: React.SyntheticEvent, value: string) => {
        setTabIndex(value);
        return value;
    };

    const formField = adviceGoals.formFields.find((goal) => goal.goalId === goalId);
    const title = formField?.data?.wealthGoalType;

    const { goalPriority } = goalsData || {};

    return (
        <>
            <StyledPositionOverlay>
                <BasicButton
                    variant="outlined"
                    onClick={exportPdf}
                    disabled={true}
                    loading={isGeneratingPdf}
                >
                    {t('CLIENT_ONBOARDING_Share_PDF')}
                </BasicButton>
            </StyledPositionOverlay>
            {probableGoalAmount ? (
                <>
                    <UniversalCard
                        cardSx={{
                            marginBottom: '24px'
                        }}
                        header="Goal Details"
                        onClick={() => {}}
                        onClickViewAll={() => {}}
                        actionMenuOptions={[
                            {
                                label: 'Delete',
                                onClick: () => setDeleteModalState(true),
                                icon: 'delete'
                            }
                        ]}
                    >
                        <div className="proposal-pdf pdf-content-container hidden-pdf-container">
                            <div id="hidden-pdf-page" className="pdf-page" ref={pdfContainerRef}>
                                <HeaderDiv>
                                    <GoalType type={type} />
                                    <Typography
                                        variant="headers.h2"
                                        label={`${goalName} |`}
                                        aria-label={goalName}
                                        ml={1.5}
                                    />
                                    <Typography
                                        label={goalType}
                                        aria-label={goalType}
                                        ml={0.4}
                                        mr={1.5}
                                        variant="headers.h2Medium"
                                    />
                                    <Chip type={goalPriority} />
                                </HeaderDiv>
                                {isGoalRealistic && (
                                    <PlanSummaryProgressBar
                                        highlightedValues={
                                            isRetirement
                                                ? [
                                                      {
                                                          amount: goalAmount,
                                                          label: t('TARGET_ANNUAL_INCOME'),
                                                          helperText: t(
                                                              'TARGET_ANNUAL_INCOME_HELPER'
                                                          )
                                                          // originalAmount: originalAmount // need to for original
                                                      },
                                                      {
                                                          amount: probableGoalAmount,
                                                          label: t('PROJECTED_ANNUAL_INCOME'),
                                                          helperText: t(
                                                              'PROJECTED_ANNUAL_INCOME_HELPER'
                                                          )
                                                      }
                                                  ]
                                                : [
                                                      {
                                                          amount: goalAmount,
                                                          label: t('TARGET_GOAL'),
                                                          helperText: t('TARGET_GOAL_HELPER')
                                                      },

                                                      {
                                                          amount: probableGoalAmount,
                                                          label: t('PROJECTED_GOAL'),
                                                          helperText: t('PROJECTED_GOAL_HELPER')
                                                      }
                                                  ]
                                        }
                                        onClickEditGoals={() => setShowGoalDrawer(true)}
                                        targetProgress={
                                            goalAmount < probableGoalAmount ? barPrecentage : 100
                                        }
                                        values={planSummaryProgressBarValues}
                                    />
                                )}
                                {isGoalRealistic ? (
                                    <ProbSuccessDiv isRecomendation={!isRecomendation}>
                                        <ProbabilityOfSuccess
                                            dangerValue={goalUnrealisticValue * 100}
                                            goalType={goalsData['goalPriority']}
                                            target={goalPriorityProbability}
                                            thresholdValue={goalPriorityProbability}
                                            value={currentGoalProbability}
                                        />
                                        <PlanInfoAndReRunSection
                                            annualContributionsAmount={
                                                calculatedAmountval.annualContribution
                                            }
                                            handleReRunButtonClicked={(selectedRecomendation) => {
                                                applyRecommendation(selectedRecomendation);
                                            }}
                                            initialInvestmentAmount={
                                                goalsData?.isIWSApplied
                                                    ? goalsData?.recommendedWealthSplit
                                                    : calculatedAmountval.initialInvestment
                                            }
                                            planReRunOptions={[
                                                {
                                                    optionLabel: 'Add a lump sum now',
                                                    key: 'oneTimeTopUp',
                                                    optionValue: analysisReport['oneTimeTopUp']
                                                },
                                                {
                                                    optionLabel: 'Increase annual contributions',
                                                    key: 'topUpAccumulation',
                                                    optionValue:
                                                        analysisReport['yearlyTopUpAccumulation']
                                                },
                                                {
                                                    optionLabel: 'Decrease annual income goal',
                                                    key: 'topUpDecumulation',
                                                    optionValue: isRetirement
                                                        ? analysisReport['yearlyTopUpDecumulation']
                                                        : 0
                                                },
                                                {
                                                    optionLabel: isRetirement
                                                        ? 'Delay retirement'
                                                        : 'Delay goal',
                                                    key: 'recommendedTenure',
                                                    optionValue: extractYearForProbability(
                                                        analysisReport['recommendedTenure']
                                                    )
                                                }
                                            ]}
                                            yearsToGoal={calculateYearsBetweenDates(
                                                startDate,
                                                isRetirement
                                                    ? goalsData?.planStartRetirement
                                                    : goalsData?.targetDate
                                            )}
                                            yearsInRetirement={calculateYearsBetweenDates(
                                                isRetirement ? goalsData?.planStartRetirement : '',
                                                isRetirement ? goalsData?.targetDate : ''
                                            )}
                                            isRetirement={isRetirement}
                                            isRecomendation={isRecomendation}
                                        />
                                    </ProbSuccessDiv>
                                ) : (
                                    <UnrealisticGoal onClickEdit={() => setShowGoalDrawer(true)} />
                                )}
                            </div>
                            <ConfirmationDialog
                                onCancellation={handleCancelDelete}
                                onConfirmation={() => handleConfirmDelete()}
                                open={deleteModalState}
                                content={`Are you sure you want to delete this goal? This action can’t be undone.`}
                                confirmButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_DELETE_TEXT')}`}
                                cancelButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}`}
                                width="100%"
                            />
                        </div>
                    </UniversalCard>

                    {isGoalRealistic && (
                        <>
                            <UniversalCard>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    variant={TabVariant.COMPACT_TAB}
                                    tabPanels={[
                                        {
                                            enabled: true,
                                            hidden: false,
                                            title: t('LABEL_PORTFOLIO_COMPOSITION'),
                                            children: (
                                                <PortfolioCompositionContainer
                                                    recommendedPortfolioId={
                                                        analysisReport['recommendedPortfolioId']
                                                    }
                                                    allMarketPortfolios={allMarketPortfolios}
                                                />
                                            )
                                        },
                                        {
                                            enabled: true,
                                            hidden: false,
                                            title: t('LABEL_ALLOCATION_OVER_TIME'),
                                            children: (
                                                <AllocationsOverTimeTab
                                                    portfolioPath={pathReport?.portfolioPath}
                                                />
                                            )
                                        }
                                    ]}
                                />
                            </UniversalCard>

                            <StyledDisclaimerContent
                                label={t('PORTFOLIO_COMPOSITION_DISCLAIMER')}
                            />

                            <ProjectedWealthPathChart
                                data={chartData}
                                targetPercentile={wealthPath?.defaultPercentile}
                                lowPercentile={wealthPath?.pessimisticPercentile}
                                highPercentile={wealthPath?.optimisticPercentile}
                                bankruptcyYear={bankruptcyYear}
                                goalPriority={goalPriority}
                            />
                        </>
                    )}
                    <StyledDisclaimerContent label={t('DISCLAIMER_SUMMARY_PORTFOLIO_1')} />
                </>
            ) : (
                <Spinner />
            )}

            {isRetirement ? (
                <RetirementGoals
                    selectedGoal={{ title: goalType, type: goalType }}
                    handleClose={() => setShowGoalDrawer(false)}
                    open={showGoalDrawer}
                    yearsInRetirement={parseInt(yearsInRetirement)}
                    goalData={{ ...goalsData, title }}
                />
            ) : (
                <WealthGoals
                    selectedGoal={{ title: goalType, type: goalType }}
                    handleClose={() => setShowGoalDrawer(false)}
                    open={showGoalDrawer}
                    goalData={{ ...goalsData, title }}
                />
            )}
        </>
    );
};

export default GoalSummary;
