import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PATPalette } from '../../../themes/palette';

function getStyles(item: string, selectedData: string[] | string, theme: Theme) {
    if (Array.isArray(selectedData)) {
        return {
            fontWeight:
                selectedData.indexOf(item) === -1 ?
                    theme.typography.fontWeightRegular :
                    theme.typography.fontWeightMedium
        };
    } else {
        return {
            fontWeight: selectedData === item ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular
        };
    }
}

export interface ComboSelectProps extends SelectProps {
    options: Array<{ value: string; label: string }>; // These are the options to select from.
    selectedValue: string | string[]; // this will be the pre selected value. if string, then it is a single select, if array, then it is a multi select
    // eslint-disable-next-line no-unused-vars
    onSelectChange(value: string | string[]): void; // this will be the callback function to get the selected value/s
    icon?: React.ElementType;
    multiple? : boolean;
    preText?: string;
}

export const ComboSelect = (props: ComboSelectProps) => {
    const {
        options,
        multiple,
        placeholder,
        label,
        icon = ExpandMoreIcon,
        hidden,
        disabled,
        selectedValue,
        onSelectChange,
        size = 'medium',
        onBlur,
        preText
    } = props;
    const theme = useTheme();
    const [selectedData, setSelectedData] = React.useState<string[] | string>(selectedValue || '');

    React.useEffect(() => {
        setSelectedData(selectedValue || '');
    }, [selectedValue]);

    const handleChange = (event: SelectChangeEvent<typeof selectedData>) => {
        const {
            target: { value }
        } = event;
        setSelectedData(
            value
            // On autofill we get a stringified value.
            // typeof value === 'string' ? value.split(',') : value
        );
        onSelectChange?.(value);
    };

    return (
        <>
            {!hidden && (
                <>
                    {label && <InputLabel id="demo-multiple-item-label">{label}</InputLabel>}
                    <Select
                        labelId="demo-multiple-item-label"
                        id="demo-multiple-item"
                        size={size}
                        displayEmpty
                        multiple={multiple}
                        value={selectedData}
                        onChange={handleChange}
                        title={'Select'}
                        renderValue={(selectedData) => {
                            if (preText && typeof selectedData === 'string') {
                                return selectedData === '' ?
                                    preText + ' ' + placeholder || 'Select' :
                                    `${preText} ${selectedData}`;
                            }
                            if (typeof selectedData === 'string' ? selectedData === '' : selectedData.length === 0) {
                                return (
                                    <em style={{ color: PATPalette.neutral.grey[200] }}>{placeholder || 'Select'}</em>
                                );
                            }
                            return selectedData;
                        }}
                        disabled={disabled}
                        inputProps={{ 'aria-label': 'Without label', onBlur }}
                        input={<OutlinedInput />}
                        fullWidth
                        placeholder={placeholder}
                        {...(icon && { IconComponent: icon })}
                    >
                        {options?.length > 0 &&
                            options?.map(({ label, value }) => (
                                <MenuItem key={value} value={value} style={getStyles(value, selectedData, theme)}>
                                    {label}
                                </MenuItem>
                            ))}
                    </Select>
                </>
            )}
        </>
    );
};
